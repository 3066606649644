export const routes = [
  {
    title: "About Me",
    href: "#about",
    Icon: "",
  },
  // {
  //   title: "Skills",
  //   href: "#skills",
  //   Icon: "",
  // },
  {
    title: "Experiance",
    href: "#experiance",
    Icon: "",
  },
  {
    title: "Projects",
    href: "#project",
    Icon: "",
  },
  {
    title: "Contact",
    href: "#contact",
    Icon: "",
  },
  {
    title: "Resume",
    href: "https://portfolio-assts.s3.ap-south-1.amazonaws.com/resume.pdf",
    resume: true,
  },
];
