export const works = [
  {
    company: "Appiness Interactive pvt ltd",

    roles: [
      {
        role: "Jr Devops Engineer",
        from: "Jan 2022",
        to: "Present",
        desc: [
          "Skilled in Jenkins for CI/CD, integrating with SonarQube, and ensuring GitHub integration across diverse environments.",
          "Experienced in managing AWS servers, identifying server types, and optimizing memory requirements for seamless integration with Jenkins.",
          "Implemented robust monitoring setups with Prometheus, Grafana, and email alerts for tracking key metrics, alongside repository management on platforms like GitHub.",
        ],
      },
      {
        role: "Software Developer ",
        from: "Aug 2021",
        to: "Jan 2022",
        desc: [
          "Extensive expertise in front-end development, specializing in crafting responsive and interactive websites for in-house projects.",
          "Proficiently employing HTML5, CSS, JavaScript, and Bootstrap to create visually appealing and engaging web interfaces.",
          "Skilled in React.js development, integrating HTML, CSS, and Bootstrap to construct dynamic and efficient user interfaces with incorporated animations for enhanced user experience.",
        ],
      },
    ],
  },
  {
    company: "Cognizant",
    roles: [
      {
        role: "Program Analyst",
        from: "Dec 2019",
        to: "Aug 2021",
        desc: [
          "Effectively resolved issues by collaborating with stakeholders, utilizing the BMS ITSM Tool to adhere to SLAs.",
          "Facilitated team growth through Knowledge Transfer sessions, fostering continuous learning and improvement.",
          "Enhanced system security and resource utilization by executing logical access reviews and managing IT asset life cycles, while ensuring business continuity through Disaster Recovery plans.",
        ],
      },
      {
        role: "Program Analyst Trainee",
        from: "Dec 2018",
        to: "Dec 2019",
        desc: [
          "Successfully completed a comprehensive three-month Java training program, gaining proficiency in Java programming fundamentals and best practices.",
          "Independently developed an insurance license management system from the ground up. This full-stack application utilized Java, JDBC, SQL for backend functionalities, and JavaScript and CSS for an interactive and visually appealing front end.",
        ],
      },
    ],
  },
];
